function Outlook(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40.847"
      height="38.296"
      viewBox="0 0 40.847 38.296"
      {...props}
    >
      <g transform="translate(0 -15.989)">
        <path
          fill="#1976d2"
          d="M275.147 112.011h-17.871a1.276 1.276 0 100 2.553h14.151l-7.843 6.1-5.624-3.577-1.368 2.155 6.382 4.059a1.277 1.277 0 001.468-.069l9.428-7.335v16.538h-16.594a1.276 1.276 0 100 2.553h17.871a1.277 1.277 0 001.276-1.276v-20.425a1.277 1.277 0 00-1.276-1.276z"
          data-name="Path 3259"
          transform="translate(-235.576 -88.361)"
        ></path>
        <path
          fill="#2196f3"
          d="M22.514 16.285a1.254 1.254 0 00-1.049-.271l-20.423 3.83A1.273 1.273 0 000 21.1v28.079a1.275 1.275 0 001.042 1.253l20.424 3.829a1.2 1.2 0 00.235.023 1.277 1.277 0 001.276-1.276v-35.74a1.273 1.273 0 00-.463-.983z"
          data-name="Path 3260"
        ></path>
        <path
          fill="#fafafa"
          d="M70.382 191.329c-3.518 0-6.382-3.436-6.382-7.659s2.864-7.659 6.382-7.659 6.382 3.436 6.382 7.659-2.864 7.659-6.382 7.659zm0-12.765c-2.111 0-3.829 2.29-3.829 5.106s1.718 5.106 3.829 5.106 3.829-2.29 3.829-5.106-1.717-5.106-3.829-5.106z"
          data-name="Path 3261"
          transform="translate(-58.894 -147.255)"
        ></path>
      </g>
    </svg>
  );
}

export default Outlook;
