const GoogleCalendar = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3687 6.63135L14.7372 5.89453L6.63186 6.63135L5.8949 13.9998L6.63172 21.3683L14.0002 22.2894L21.3687 21.3683L22.1055 13.8157L21.3687 6.63135Z"
      fill="white"
    />
    <path
      d="M9.65446 18.0642C9.1037 17.6921 8.72234 17.1488 8.51416 16.4303L9.79264 15.9035C9.9087 16.3456 10.1113 16.6882 10.4005 16.9314C10.6879 17.1745 11.0379 17.2942 11.4469 17.2942C11.8651 17.2942 12.2243 17.1671 12.5245 16.9129C12.8246 16.6586 12.9758 16.3344 12.9758 15.9421C12.9758 15.5406 12.8173 15.2126 12.5005 14.9585C12.1837 14.7044 11.7858 14.5771 11.3105 14.5771H10.5719V13.3117H11.2349C11.6439 13.3117 11.9884 13.2012 12.2684 12.9801C12.5484 12.7591 12.6884 12.457 12.6884 12.072C12.6884 11.7294 12.5631 11.4567 12.3126 11.2523C12.0622 11.0479 11.7452 10.9447 11.3602 10.9447C10.9845 10.9447 10.686 11.0442 10.4649 11.245C10.2439 11.4457 10.0836 11.6926 9.98234 11.9836L8.71688 11.4568C8.88446 10.9815 9.19218 10.5615 9.6434 10.1986C10.0948 9.83574 10.6713 9.65332 11.3713 9.65332C11.8889 9.65332 12.3549 9.75286 12.7676 9.95362C13.1802 10.1544 13.5045 10.4326 13.7384 10.7862C13.9723 11.1417 14.0884 11.5397 14.0884 11.9817C14.0884 12.433 13.9798 12.8142 13.7623 13.1274C13.5449 13.4406 13.2778 13.68 12.961 13.8477V13.9232C13.3792 14.0982 13.7199 14.3653 13.9889 14.7245C14.256 15.0838 14.3904 15.513 14.3904 16.0141C14.3904 16.5151 14.2633 16.9627 14.009 17.3551C13.7548 17.7476 13.403 18.057 12.9572 18.2817C12.5096 18.5064 12.0067 18.6206 11.4486 18.6206C10.802 18.6224 10.2052 18.4364 9.65446 18.0642Z"
      fill="#1A73E8"
    />
    <path
      d="M17.5002 11.7196L16.1038 12.7346L15.402 11.6699L17.9202 9.85352H18.8855V18.4211H17.5002V11.7196Z"
      fill="#1A73E8"
    />
    <path
      d="M21.3685 27.9997L28.0001 21.3682L24.6843 19.8945L21.3685 21.3682L19.8949 24.6839L21.3685 27.9997Z"
      fill="#EA4335"
    />
    <path
      d="M5.15771 24.6839L6.63135 27.9997H21.3682V21.3682H6.63135L5.15771 24.6839Z"
      fill="#34A853"
    />
    <path
      d="M2.21046 0C0.98924 0 0 0.98924 0 2.21046V21.3683L3.31576 22.842L6.63152 21.3683V6.63152H21.3683L22.842 3.31576L21.3685 0H2.21046Z"
      fill="#4285F4"
    />
    <path
      d="M0 21.3682V25.7892C0 27.0106 0.98924 27.9997 2.21046 27.9997H6.63152V21.3682H0Z"
      fill="#188038"
    />
    <path
      d="M21.3685 6.63184V21.3687H28V6.63184L24.6843 5.1582L21.3685 6.63184Z"
      fill="#FBBC04"
    />
    <path
      d="M28 6.63152V2.21046C28 0.9891 27.0108 0 25.7896 0H21.3685V6.63152H28Z"
      fill="#1967D2"
    />
  </svg>
);

export default GoogleCalendar;
